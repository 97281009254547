.InformationContainer {
    font-family: 'LiberationMonoRegular';
    font-size: 120%;
    margin: 3rem;
    color: #234572;
}

.InformationName {
    font-size: 140%;
}

.a {
    color: inherit; /* blue colors for links too */
    text-decoration: underline; /* no underline */
}

.a:hover {
    font-weight: bold;
}

.InformationText {
    padding-top: 0.5rem;
}

.InformationTextFilter {
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
    font-weight: bold;
}

.Filter {
    text-decoration: underline;
    font-weight: bold;
}

.InformationResume {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
    font-weight: bold;
    text-decoration: underline;
}

.InformationResume:hover {
    font-style: italic;
}

.InformationLink {
    padding-top: 0.5rem;
    text-decoration: underline;
}

.InformationLink:hover {
    font-weight: bold;
}

.Skills {
    padding-bottom: 0.5rem;
}

.Tech {
    padding-bottom: 1.5rem;
}

.FrontEnd {
    color: cornflowerblue;
}

.FrontEnd:hover {
    font-weight: bold;
    text-decoration: underline;
}

.FullStack {
    color: #AF4C79;
}

.FullStack:hover {
    font-weight: bold;
    text-decoration: underline;
}

.Algorithms {
    color: olivedrab;
}

.Algorithms:hover {
    font-weight: bold;
    text-decoration: underline;
}

.UXResearch {
    color: coral;
}

.UXResearch:hover {
    font-weight: bold;
    text-decoration: underline;
}

.Database {
    color: orchid;
}

.Database:hover {
    font-weight: bold;
    text-decoration: underline;
}

.Experience {
    padding-bottom: 0.5rem;
}

.Role {
    padding-left: 7.5rem;
}

.ExperienceYear {
    margin-right: 1rem;
}

@media screen and (min-width: 1500px) {
    .InformationContainer {
        font-size: 140%;
        margin: 4rem;
    }
    
    .InformationName {
        font-size: 160%;
    }
  }
  