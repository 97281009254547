.ProjectContainer {
    color: white;
    font-family: 'LiberationMonoRegular';
    font-size: 120%;
    margin: 3rem;
}

.ProjectList {
    list-style-type: none;
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */
}

.ProjectElement {
    margin-bottom: 1rem;
}

.ProjectElement:hover {
    color: #80C0C0;
}

.ProjectName {
    margin-left: 1rem;
}

.ProjectYear {
    font-size: 70%;
    /* color: #44B5D2; */
    color: #80C0C0;
}

.ProjectTech {
    font-size: 70%;
    margin-left: 1rem;
}

.ProjectTagline {
    font-size: 70%;
    margin-left: 3.1rem;
}

.Back {
    color: coral;
    padding-bottom: 1.5rem;
}

.Button {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.Button:hover {
    font-weight: bold;
}

.DetailContainer {
    padding-bottom: 2rem;
}

.DetailName {
    font-size: 140%;
    padding-bottom: 1.5rem;
    padding-top: 0.25rem;
}

.DetailDescription {
    padding-bottom: 1.5rem;
}

.DetailImage {
    max-width:100%;
    max-height:100%;
    padding-bottom: 0.75rem;
}

.DetailLinks {
    color: cornflowerblue; /* blue colors for links too */
    text-decoration: underline; /* no underline */
    margin-right: 0.5rem;
}

.DetailLinks:hover {
    font-weight: bold;
}

.DetailTechContainer {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.DetailTech {
    float: left;
    margin-right: 1rem;
}

.ProjectFrontEnd {
    color: cornflowerblue;
    font-size: 70%;
    margin-left: 1rem;
}

.ProjectFullStack {
    color: #AF4C79;
    font-size: 70%;
    margin-left: 1rem;
}

.ProjectAlgorithms {
    color: olivedrab;
    font-size: 70%;
    margin-left: 1rem;
}

.ProjectUXResearch {
    color: coral;
    font-size: 70%;
    margin-left: 1rem;
}

.ProjectDatabase {
    color: orchid;
    font-size: 70%;
    margin-left: 1rem;
}

.DetailProjectFrontEnd {
    color: cornflowerblue;
}

.DetailProjectFullStack {
    color: #AF4C79;
}

.DetailProjectAlgorithms {
    color: olivedrab;
}

.DetailProjectUXResearch {
    color: coral;
}

.DetailProjectDatabase {
    color: orchid;
}

@media screen and (max-width: 768px) {
    .ProjectName {
        display: block;
        margin-left: 0rem;
    }
    
    .ProjectTagline {
        margin-left: 0rem;
        margin-top: 0.1rem;
    }

    .ProjectFrontEnd {
        margin-left: 0rem;
    }

    .ProjectFullStack {
        margin-left: 0rem;
    }

    .ProjectAlgorithms {
        margin-left: 0rem;
    }

    .ProjectUXResearch {
        margin-left: 0rem;
    }

    .ProjectDatabase {
        margin-left: 0rem;
    }
  }

  @media screen and (min-width: 1500px) {
    .ProjectContainer {
        font-size: 140%;
        margin: 4rem;
    }
    
    .ProjectYear {
        font-size: 70%;
    }
    
    .ProjectTech {
        font-size: 70%;
    }
    
    .ProjectTagline {
        font-size: 70%;
        margin-left: 3.4rem;
    }
  }
