.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Loading {
  font-family: 'LiberationMonoRegular';
  color: #234572;
  font-weight: bold;
  font-size: 140%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.SplitScreen {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  height: fit-content;
}

.LeftPanel {
  width: 50vw;
  background-color: whitesmoke;
}

.RightPanel {
  width: 50vw;
  background-color: #282828;
}

@media screen and (max-width: 768px) {
  .SplitScreen {
    display: flex;
    flex-direction: column;
  }

  .LeftPanel, .RightPanel {
    width: 100vw;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* loading animation stuff */
